import EncryptUtility from "../../utility/js/encrypt-utility";
import AddFavourite from "../../utility/js/addFav";
import breadcrumbComp from "../../common/breadcrumb-comp";
import PrinterDetails from "../../common/printer-details";
import Utility from "../../../shared/utility.js";
import html2pdf from "html2pdf.js";
export default {
  data() {
    return {
      refresh: true,
      backSlash: true,
      hideDataTable: true,
      actionList: [],
      mainPage: "",
      subPage: "",
      pageUrl: "",
      favouriteList: [],
      showAdd: false,
      userId: EncryptUtility.localStorageDecrypt("userID"),
      isPrinter: true,
      unitIdentifier: "",
      isFormValid: false,
      pass: null,
      searchRecords: "",
      unitIdentifyValid: false,
      btnName: "",
      searchClicked: false,
      mainKey: null,
      shippingResult: [],
      searchPackSlip: "",
      searchOrder: "",
      selectedType: "",
      primaryName: EncryptUtility.localStorageDecrypt("primaryName"),
      defaultName: EncryptUtility.localStorageDecrypt("defaultName"),
      searchDisabled: true,
      printBtn: true,
      dynamicLabel: "",
      disableSearchOrder: false,
      disablePackSlip: false,
      totalRecords: 0,
      reportObj: [],
      showPrimary: true,
      headersReprintShipping: [
        {
          text: "Packslip",
          value: "packslip",
          class: "primary customwhite--text",
        },
        {
          text: "Ship Date",
          value: "shipdate",
          class: "primary customwhite--text",
        },
        {
          text: "Waybill#",
          value: "waybill",
          class: "primary customwhite--text",
        },
      ],
      max50Rule: [(v) => (v || "").length <= 50 || "Max Length of 50 character", (v) => !!v || "Field is required"],
      max5Rule: [(v) => (v || "").length <= 5 || "Max Length of 5 character", (v) => !!v || "Field is required"],
      searchList: [
        {
          text: "ORDER",
          value: "O",
        },
        {
          text: "PACKING SLIP",
          value: "P",
        },
        {
          text: "WAYBILL",
          value: "W",
        },
        {
          text: "BCN",
          value: "B",
        },
        {
          text: "SERIAL#",
          value: "S",
        },
        {
          text: "ESN",
          value: "E",
        },
      ],
      printerConfigured: false,
    };
  },
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
    this.searchDisabled = true;
    if (EncryptUtility.localStorageDecrypt("defaultName") !== null && EncryptUtility.localStorageDecrypt("defaultName") !== undefined) {
      if (EncryptUtility.localStorageDecrypt("primaryLabelSize") !== null && EncryptUtility.localStorageDecrypt("primaryLabelSize") !== undefined) {
        if (
          EncryptUtility.localStorageDecrypt("primaryPrinterType") !== null &&
          EncryptUtility.localStorageDecrypt("primaryPrinterType") !== undefined
        ) {
          this.primaryLabelSize = EncryptUtility.localStorageDecrypt("primaryLabelSize");
          this.primaryPrinterType = EncryptUtility.localStorageDecrypt("primaryPrinterType");
          this.printBtn = true;
          this.disableSearchOrder = false;
          this.disablePackSlip = false;
          this.printerConfigured = false;
        }
      } else {
        this.printBtn = true;
        this.printerConfigured = true;
        this.searchDisabled = true;
        this.disableSearchOrder = true;
        this.disablePackSlip = true;
        let Alert = {
          type: "error",
          isShow: true,
          message: "Receiving/Inventory Printer is not configured, please configure printer.",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
      }
    } else {
      this.printBtn = true;
      this.printerConfigured = true;
      this.searchDisabled = true;
      this.disableSearchOrder = true;
      this.disablePackSlip = true;
      let Alert = {
        type: "error",
        isShow: true,
        message: "Default Printer is not configured, please configure printer.",
      };
      this.$store.commit("ConfigModule/Alert", Alert);
    }
  },
  mounted() {},
  methods: {
    //Order search API call
    getActionData() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      let obj = {
        box_key: 0,
        lpn_key: 0,
        packslip: this.searchPackSlip,
        ordno: this.searchOrder,
        user_key: parseInt(this.userId),
        po_so: "",
        order_key: "",
      };
      this.axios
        .post("/sh/shipping_packslip", obj)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            if (this.searchPackSlip) {
              this.hideDataTable = false;
              this.printBtn = false;
            } else {
              this.hideDataTable = true;
              this.selectedLabel = [];
              let responsedata = [];
              if (this.searchPackSlip != "") {
                this.shippingResult = [];
                responsedata = JSON.parse(response?.data?.body?.message);
                const newArr = responsedata?.Result4.map((obj) => {
                  return {
                    ...obj,
                    shipdate: Utility.convertESTToLocal(obj.shipdate),
                  };
                });
                this.shippingResult = newArr;
              } else {
                this.shippingResult = [];
                responsedata = JSON.parse(response.data.body.message);
                const newArr = responsedata.Result1.map((obj) => {
                  return {
                    ...obj,
                    shipdate: Utility.convertESTToLocal(obj.shipdate),
                  };
                });
                this.shippingResult = newArr;
              }
              this.totalRecords = this.shippingResult?.length;
              this.disableSearchOrder = true;
              this.disablePackSlip = true;
              this.searchDisabled = true;
              if (this.totalRecords > 0) {
                this.printBtn = false;
              }
            }
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          this.disableSearchOrder = false;
          this.disablePackSlip = false;
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //Order entry into text box
    inputOrderEnter() {
      this.$nextTick(() => {
        this.searchPackSlip = "";
        this.searchDisabled = false;
        this.searchOrder = this.searchOrder?.toUpperCase();
        this.$refs.UnitIdentifierForm.resetValidation();
      });
    },

    //Pack slip details entry
    inputPackSlipEnter() {
      this.$nextTick(() => {
        this.searchOrder = "";
        this.searchDisabled = false;
        this.searchPackSlip = this.searchPackSlip?.toUpperCase();
        this.$refs.UnitIdentifierForm.resetValidation();
      });
    },
    //On search cancel event
    resetFunction() {
      this.searchDisabled = true;
      this.disablePackSlip = false;
      this.searchOrder = "";
      this.searchPackSlip = "";
      this.totalRecords = 0;
      this.printBtn = true;
      this.shippingResult = [];
      if (EncryptUtility.localStorageDecrypt("primaryLabelSize") !== null && EncryptUtility.localStorageDecrypt("primaryLabelSize") !== undefined) {
        if (
          EncryptUtility.localStorageDecrypt("primaryPrinterType") !== null &&
          EncryptUtility.localStorageDecrypt("primaryPrinterType") !== undefined
        ) {
          this.disableSearchOrder = false;
          this.disablePackSlip = false;
          this.printerConfigured = false;
        }
      } else {
        this.printerConfigured = true;
        this.searchDisabled = true;
        this.disableSearchOrder = true;
        this.disablePackSlip = true;
      }
      this.$refs.UnitIdentifierForm.resetValidation();
    },
    //Print Button click event
    savePdfShippingLabel() {
      if (!this.hideDataTable) {
        this.selectedLabel = this.searchPackSlip;
      }
      if (this.selectedLabel != undefined || !this.hideDataTable) {
        if (this.selectedLabel?.length > 0 || !this.hideDataTable) {
          let LoaderDialog = {
            visible: true,
            title: "Please Wait...",
          };
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          this.reportObj = [];
          if (!this.hideDataTable) {
            let obj = {
              box_key: 0, // Hard Coded as Per API Documentation
              lpn_key: 0, // Hard Coded as Per API Documentation
              packslip: this.searchPackSlip, // Hard Coded as Per API Documentation
              ordno: "", // Hard Coded as Per API Documentation
              user_key: parseInt(this.userId),
              po_so: "", // Hard Coded as Per API Documentation
              order_key: "", // Hard Coded as Per API Documentation
            };

            this.reportObj.push(obj);
          } else {
            for (let i = 0; i < this.selectedLabel.length; i++) {
              let obj = {
                box_key: 0, // Hard Coded as Per API Documentation
                lpn_key: 0, // Hard Coded as Per API Documentation
                packslip: this.selectedLabel[i].packslip,
                ordno: "", // Hard Coded as Per API Documentation
                user_key: parseInt(this.userId),
                po_so: "", // Hard Coded as Per API Documentation
                order_key: "", // Hard Coded as Per API Documentation
              };
              this.reportObj.push(obj);
            }
          }
          for (let k = 0; k < this.reportObj.length; k++) {
            this.axios
              .post("/sh/shipping_packslip_report", this.reportObj[k])
              .then((response) => {
                if (response.status == 200 || response.status == 204) {
                  //window.open(response.data.body.message, "_blank");
                  // var pdf_response = atob(response.data.body.message);
                  //window.open("data:application/pdf;base64," + response.data.body.message);

                  // html2pdf(btoa(response.data.body.message), {
                  //   margin: 0.5,
                  //   filename: this.pdfName,
                  //   image: { type: "jpeg", quality: 0.98 },
                  //   html2canvas: { dpi: 190, letterRendering: true },
                  //   jsPDF: { unit: "in", format: "letter", orientation: "landscape" },
                  // });

                  const linkSource = `data:application/pdf;base64,${response.data.body.message}`;
                  const downloadLink = document.createElement("a");
                  const fileName =
                    "Ship_Label_" + new Date().toISOString().replace("T", "_").replace("Z", "").replace(".", "").replaceAll("-", "_") + ".pdf";
                  downloadLink.href = linkSource;
                  downloadLink.download = fileName;
                  downloadLink.click();

                  LoaderDialog.visible = false;
                  this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                  this.resetFunction();
                  this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                  let Alert = {
                    type: "success",
                    isShow: true,
                    message: "Packslip exported successfully",
                  };
                  this.$store.commit("ConfigModule/Alert", Alert);
                } else {
                  LoaderDialog.visible = false;
                  this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                  let Alert = {
                    type: "error",
                    isShow: true,
                    message: response.data.message,
                  };
                  this.$store.commit("ConfigModule/Alert", Alert);
                }
                LoaderDialog.visible = false;
                this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              })
              .catch((error) => {
                LoaderDialog.visible = false;
                this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                let Alert = {
                  type: "error",
                  isShow: true,
                  message: Utility.apiError(error),
                };
                this.$store.commit("ConfigModule/Alert", Alert);
                this.errorMessage = error.message;
              });
          }
        } else {
          let Alert = {
            type: "error",
            isShow: true,
            message: "Please select any Packslip to print",
          };
          this.$store.commit("ConfigModule/Alert", Alert);
        }
      } else {
        let Alert = {
          type: "error",
          isShow: true,
          message: "Please select any Packslip to print",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
      }
    },
    //Save as PDF button click event
    printShippingLabel() {
      if (EncryptUtility.localStorageDecrypt("defaultName") !== null && EncryptUtility.localStorageDecrypt("defaultName") !== undefined) {
        if (this.selectedLabel != undefined || !this.hideDataTable) {
          if (this.selectedLabel?.length > 0 || !this.hideDataTable) {
            let LoaderDialog = {
              visible: true,
              title: "Please Wait...",
            };
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            this.reportObj = [];
            if (!this.hideDataTable) {
              let obj = {
                box_key: 0, // Hard coded as per API documentation
                lpn_key: 0, // Hard coded as per API documentation
                packslip: this.searchPackSlip,
                ordno: "", // Hard coded as per API documentation
                user_key: parseInt(this.userId),
                po_so: "", // Hard coded as per API documentation
                order_key: "", // Hard coded as per API documentation
              };

              this.reportObj.push(obj);
            } else {
              for (let i = 0; i < this.selectedLabel.length; i++) {
                let obj = {
                  box_key: 0, // Hard Coded as Per API Documentation
                  lpn_key: 0, // Hard Coded as Per API Documentation
                  packslip: this.selectedLabel[i].packslip,
                  ordno: "", // Hard Coded as Per API Documentation
                  user_key: parseInt(this.userId),
                  po_so: "", // Hard Coded as Per API Documentation
                  order_key: "", // Hard Coded as Per API Documentation
                };
                this.reportObj.push(obj);
              }
            }
            for (let k = 0; k < this.reportObj.length; k++) {
              this.axios
                .post("sh/shipping_packslip_report", this.reportObj[k])
                .then((response) => {
                  if (response.status == 200 || response.status == 204) {
                    this.pdfName = "Reprint_Packslip_" + this.reportObj[k].packslip + ".pdf";
                    let responseData = response.data.body.message;
                    //Default PDF Printer is consumed
                    responseData ? Utility.printZPLPDF(responseData, this.defaultName) : "";
                    this.selectedLabel = [];
                    this.resetFunction();
                    this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                    let Alert = {
                      type: "success",
                      isShow: true,
                      message: "Packslip printed successfully",
                    };
                    this.$store.commit("ConfigModule/Alert", Alert);
                  } else {
                    LoaderDialog.visible = false;
                    this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                    let Alert = {
                      type: "error",
                      isShow: true,
                      message: response.data.message,
                    };
                    this.$store.commit("ConfigModule/Alert", Alert);
                  }
                  LoaderDialog.visible = false;
                  this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                })
                .catch((error) => {
                  LoaderDialog.visible = false;
                  this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                  let Alert = {
                    type: "error",
                    isShow: true,
                    message: Utility.apiError(error),
                  };
                  this.$store.commit("ConfigModule/Alert", Alert);
                });
            }
          } else {
            let Alert = {
              type: "error",
              isShow: true,
              message: "Please select any Packslip to print",
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
        } else {
          let Alert = {
            type: "error",
            isShow: true,
            message: "Please select any Packslip to print",
          };
          this.$store.commit("ConfigModule/Alert", Alert);
        }
      } else {
        let Alert = {
          type: "error",
          isShow: true,
          message: "Default Printer is not configured, please configure printer.",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
      }
    },
    //Printer Configured
    printerDetails(labelPrinter) {
      if (!labelPrinter) {
        this.primaryLabelSize = EncryptUtility.localStorageDecrypt("primaryLabelSize");
        this.primaryPrinterType = EncryptUtility.localStorageDecrypt("primaryPrinterType");
        this.printBtn = true;
        this.shippingResult = [];
        this.printerConfigured = false;
        this.disableSearchOrder = false;
        this.disablePackSlip = false;
      } else {
        this.printBtn = true;
        this.shippingResult = [];
        this.printerConfigured = true;
        this.searchDisabled = true;
        this.disableSearchOrder = true;
        this.disablePackSlip = true;
      }
    },
    //To add or remove Favourite
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },
  },
  components: {
    breadcrumbComp,
    PrinterDetails,
    html2pdf,
  },
};
